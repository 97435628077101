import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../components/global.css'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const ScWrapper = styled.div`
  width: 720px;
  height: 1280px;
  overflow: hidden;

  transform: rotate(270deg);
  position: absolute;
  top: -280px;
  left: 280px;
`

const ScMedia = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

class Signage extends React.Component {
  render() {
    let party = get(this, 'props.data.contentfulDigitalSignage.party')

    return (
      <ScWrapper>
        <ScMedia image={party.gatsbyImageData} alt={party.title} />
      </ScWrapper>
    )
  }
}

export default Signage

export const pageQuery = graphql`
  query LP_SignagePartyQuery {
    contentfulDigitalSignage(contentful_id: { eq: "1smdCOyQzJQ8Aho0WiRw81" }) {
      party {
        title
        gatsbyImageData(placeholder: BLURRED, quality: 100, cropFocus: TOP)
        fluid {
          src
        }
      }
    }
  }
`
